<template>
    <onboarding-layout>
        <div class="wrapper pa-4">
            <h2>Welkom bij Dexxter!</h2>
            <p>Er is een verificatiemail naar jouw e-mailadres verstuurd.</p>
        </div>
    </onboarding-layout>
</template>

<script>
import OnboardingLayout from '../layouts/OnboardingLayout.vue';
export default {
    components: { OnboardingLayout }
};
</script>

<style lang="scss" scoped>
.wrapper {
    text-align: center;
}
</style>
